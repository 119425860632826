
<nav id="mainNav" #navigationRef class="navbar navbar-light navbar-expand-md bg-white fixed-top shadow">
  <div class="container-md">
    <a class="navbar-brand" routerLink="/">
      <picture>
        <source srcset="assets/images/logo-50.webp" type="image/webp">
        <source srcset="assets/images/logo-50.jpg" type="image/jpg">
        <img class="logo-img"
          width="124"
          height="50"
          loading="lazy"
          alt="Paquetería y mensajería en movimiento" />
      </picture>
    </a>

    <button
      class="navbar-toggler" id="btnMenu" (click)="openMenu()">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse collapse" id="navbarNav">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
        <a class="nav-link"  routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          {{'main.start' | translate}}
        </a>
        </li>
        <li class="nav-item">
        <a class="nav-link"  routerLink="/servicios" routerLinkActive="active">
          {{'main.services' | translate}}
        </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" (click)="openMenu('cliente-menu')" id="navbarDropdown" role="button" data-toggle="dropdown" >
            {{'main.client' | translate}}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" id="cliente-menu">
            <a class="dropdown-item disabled" routerLink="/recoleccion" aria-disabled="true"  routerLinkActive="active" (click)="openMenu('cliente-menu')"> {{'main.client.schedule_pickup' | translate}}</a>
            <a class="dropdown-item" href="#" (click)="openMenu('cliente-menu')">{{'main.client.track_package' | translate}}</a>
            <a class="dropdown-item" routerLink="/facturacion" routerLinkActive="active" (click)="openMenu('cliente-menu')">{{'main.client.invoice' | translate}}</a>
            <a class="dropdown-item" href="/login">{{'main.client.portal_pago' | translate}}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="http://www.pmmentuempresa.com">{{'main.client.your_business' | translate}}</a>
            <a class="dropdown-item" href="https://pmm.com.mx/prepago">{{'main.client.Prepaid' | translate}}</a>
            <!--<a class="dropdown-item" href="https://pmm.com.mx/cake">{{'main.client.Ecommerce' | translate}}</a>-->
          </div>
        </li>
        <li class="nav-item">
        <a class="nav-link"  routerLink="/cobertura" routerLinkActive="active">
          {{'main.coverage' | translate}}
        </a>
        </li>
        <li class="nav-item"><a class="nav-link"><i class="fas fa-phone-volume" style="color: #64b100;"></i> 669 915 8500</a></li>
        <li class="nav-item">
        <a class="nav-link"  href="/tiendapmm" routerLinkActive="active">
          <i class="fas fa-store" style="color: #64b100;"></i>
        </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
